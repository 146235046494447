import { formatDateTime } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { AVAILABLE_DATE_OPERATORS, DEFAULT_DATE_TIME_OPERATORS } from './operators';
const filterOperators = DEFAULT_DATE_TIME_OPERATORS.filter((op) => AVAILABLE_DATE_OPERATORS.includes(op.value));
export function useStartedAtColumn() {
    const { t } = useTranslation('searchApp', { keyPrefix: 'grid' });
    return {
        field: 'call.started_at',
        headerName: t('startTime', 'Started at'),
        type: 'dateTime',
        valueFormatter: (value) => (value ? formatDateTime(value) : ''),
        flex: 2,
        filterOperators,
    };
}
