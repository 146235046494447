import { getGridBooleanOperators, getGridDateOperators, getGridNumericOperators, } from 'lib/cortiUI';
export const DEFAULT_DATE_TIME_OPERATORS = getGridDateOperators(true);
export const DEFAULT_BOOLEAN_OPERATORS = getGridBooleanOperators();
export const DEFAULT_NUMERIC_OPERATORS = getGridNumericOperators();
export const AVAILABLE_DATE_OPERATORS = [
    'after',
    'onOrAfter',
    'before',
    'onOrBefore',
    'isEmpty',
    'isNotEmpty',
];
export const AVAILABLE_BOOLEAN_OPERATORS = ['is'];
