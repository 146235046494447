import { useTranslation } from '@corti/i18n';
import { Formatters } from '@corti/strings';
import { DurationFilterInput } from '../../Grid/GridFilterTimeInput';
import { DEFAULT_NUMERIC_OPERATORS } from './operators';
export function useDurationColumn() {
    const { t } = useTranslation('searchApp', { keyPrefix: 'grid' });
    const AVAILABLE_DURATION_OPERATORS = {
        '=': t('filterOperator.equal', 'is equal to'),
        '!=': t('filterOperator.notEqual', 'is not equal to'),
        '>': t('filterOperator.gt', 'is greater than'),
        '>=': t('filterOperator.gte', 'is greater than or equal to'),
        '<': t('filterOperator.lt', 'less than'),
        '<=': t('filterOperator.lte', 'is less than or equal to'),
        isEmpty: t('filterOperator.empty', 'is empty'),
        isNotEmpty: t('filterOperator.notEmpty', 'is not empty'),
    };
    const filterOperators = DEFAULT_NUMERIC_OPERATORS.filter((item) => Object.keys(AVAILABLE_DURATION_OPERATORS).includes(item.value)).map((item) => {
        var _a;
        return (Object.assign(Object.assign({}, item), { label: (_a = AVAILABLE_DURATION_OPERATORS[item.value]) !== null && _a !== void 0 ? _a : item.label, InputComponent: item.requiresFilterValue !== false ? DurationFilterInput : undefined }));
    });
    return {
        field: 'call.duration_millis',
        type: 'number',
        headerName: t('searchApp:grid.duration', 'Duration'),
        flex: 2,
        align: 'left',
        headerAlign: 'left',
        filterable: true,
        valueFormatter: (value) => Formatters.msToDuration(value),
        filterOperators,
    };
}
