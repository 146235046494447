import { intl } from '@corti/i18n';
export const CHART_COLOR = '#447AEA';
export const getChartOptions = (theme, x, y, data) => {
    return {
        chart: {
            type: 'line',
            backgroundColor: 'transparent',
            style: {
                fontFamily: theme.typography.fontFamily,
            },
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        time: {
            useUTC: false,
        },
        xAxis: {
            labels: {
                style: {
                    color: theme.palette.text.primary,
                },
            },
            type: 'datetime',
            maxPadding: 0,
            crosshair: true,
            // Highchart config requires to expand a date range because Core data has different date range regarding time zones.
            min: x.min,
            max: x.max,
            startOnTick: true,
            dateTimeLabelFormats: {
                month: '%b',
            },
        },
        yAxis: {
            title: {
                text: '',
            },
            min: y.min,
            max: y.max,
            tickInterval: 20,
            gridLineColor: theme.palette.grey[300],
            labels: {
                style: {
                    color: theme.palette.text.hint,
                },
            },
            plotLines: [],
        },
        title: {
            text: '',
        },
        plotOptions: {
            series: {
                clip: false,
                enableMouseTracking: true,
            },
        },
        tooltip: {
            backgroundColor: theme.palette.background.secondary,
            borderColor: theme.palette.background.divider,
            style: {
                color: theme.palette.text.primary,
            },
        },
        series: [
            {
                name: intl.t('missionControlApp:averageCompliance', 'Average compliance'),
                type: 'spline',
                color: CHART_COLOR,
                data,
            },
        ],
    };
};
