import { useTranslation } from '@corti/i18n';
import { repositories } from 'core/repositories';
import { useDurationColumn } from './duration';
import { useHasScreenRecordingsColumn } from './hasScreenRecordings';
import { useStartedAtColumn } from './startedAt';
import { makeUserOptions, usersValueFormatter } from './utils';
export function useDataGridColumns() {
    const { t } = useTranslation('searchApp', { keyPrefix: 'grid' });
    const startedAtColumn = useStartedAtColumn();
    const durationColumn = useDurationColumn();
    const hasScreenRecordingsColumn = useHasScreenRecordingsColumn();
    const userOptions = makeUserOptions(repositories.users.users);
    return [
        startedAtColumn,
        {
            field: 'call.inbound_number',
            headerName: t('inboundPhone', 'Receiver Identifier'),
            flex: 2,
            type: 'string',
        },
        {
            field: 'call.direction',
            headerName: t('direction', 'Direction'),
            flex: 2,
            type: 'singleSelect',
            filterable: true,
            valueOptions: ['INBOUND', 'OUTBOUND', 'INTERNAL'],
        },
        {
            field: 'call.source_system',
            headerName: t('sourceSystem', 'Source System'),
            flex: 2,
            type: 'string',
            filterable: true,
        },
        durationColumn,
        {
            field: 'call.outbound_number',
            headerName: t('outboundPhone', 'Caller Identifier'),
            flex: 2,
            type: 'string',
        },
        {
            field: 'call.participants',
            headerName: t('participants', 'Participants'),
            flex: 2,
            type: 'singleSelect',
            filterable: true,
            valueFormatter: usersValueFormatter,
            valueOptions: userOptions,
            sortable: true,
        },
        {
            field: 'reviews.created_by',
            headerName: t('assessedBy', 'Assessed by'),
            flex: 2,
            type: 'singleSelect',
            filterable: true,
            valueFormatter: usersValueFormatter,
            valueOptions: userOptions,
            sortable: false,
        },
        hasScreenRecordingsColumn,
    ];
}
