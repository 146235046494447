import React from 'react';
import { useTranslation } from '@corti/i18n';
import { FormControl, InputLabel, MenuItem, Select, } from 'lib/cortiUI';
export const BooleanFilterInput = ({ item, applyValue, options, label, }) => {
    const { t } = useTranslation('searchApp', { keyPrefix: 'grid' });
    const handleChange = (event) => {
        const value = event.target.value === 'true';
        applyValue(Object.assign(Object.assign({}, item), { value }));
    };
    const mapOptions = options.map((option) => ({
        label: option.label,
        value: option.value ? 'true' : 'false',
    }));
    return (React.createElement(FormControl, { fullWidth: true, variant: "standard" },
        React.createElement(InputLabel, { shrink: true }, label !== null && label !== void 0 ? label : t('defaultLabel', 'Value')),
        React.createElement(Select, { value: item.value === true ? 'true' : item.value === false ? 'false' : '', onChange: handleChange, displayEmpty: true }, mapOptions.map((option) => (React.createElement(MenuItem, { value: option.value, key: option.value }, option.label))))));
};
