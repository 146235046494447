import { useTranslation } from '@corti/i18n';
import { BooleanFilterInput } from '../../Grid/BooleanFilterInput';
import { AVAILABLE_BOOLEAN_OPERATORS, DEFAULT_BOOLEAN_OPERATORS } from './operators';
export function useHasScreenRecordingsColumn() {
    const { t } = useTranslation('searchApp', { keyPrefix: 'grid' });
    const valueOptions = [
        {
            label: t('hasScreenRecordingFilterOption.yes', 'Available'),
            value: true,
        },
        {
            label: t('hasScreenRecordingFilterOption.no', 'Not available'),
            value: false,
        },
    ];
    return {
        field: 'has_screen_recordings',
        headerName: t('hasScreenRecording.column', 'Has screen recording'),
        flex: 2,
        align: 'center',
        type: 'boolean',
        filterable: true,
        sortable: true,
        filterOperators: DEFAULT_BOOLEAN_OPERATORS.filter((op) => AVAILABLE_BOOLEAN_OPERATORS.includes(op.value)).map((op) => (Object.assign(Object.assign({}, op), { InputComponent: BooleanFilterInput, InputComponentProps: {
                label: t('hasScreenRecordingFilterInputLabel', 'Value'),
                options: valueOptions,
            }, getApplyFilterFn: (filterItem) => {
                if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                    return null;
                }
                return (value) => {
                    return value;
                };
            } }))),
    };
}
