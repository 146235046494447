export function makeUserOptions(users) {
    return users.map((u) => ({ label: u.name, value: u.id }));
}
/**
 *
 * @param value list of user names
 * @returns string of user names for the DataGrid cell value
 */
export function usersValueFormatter(value) {
    return value.join(',');
}
