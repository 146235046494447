import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highcharts';
import { minBy } from 'lodash';
import React from 'react';
import { addDays, convertUtcToZonedTime, endOfDay, startOfDay, subDays } from '@corti/date';
import { useTranslation } from '@corti/i18n';
import { useTheme } from '@corti/theme';
import { trackerService } from 'browser/services/init';
import { Card } from 'lib/cortiUI';
import { useParameters } from '../../context';
import { ChartDataBreakdownSelect } from './components/ChartDataBreakdownSelect';
import { ChartHeader } from './components/ChartHeader';
import { ChartLoader } from './components/ChartLoader';
import { ResizeObserverContainer } from './components/ResizeObserverContainer';
import { getChartOptions, useChartData } from './utils';
export const Chart = () => {
    var _a;
    const theme = useTheme();
    const { t } = useTranslation('missionControlApp', { keyPrefix: 'chart' });
    const PERIOD_VARIANTS = [
        {
            type: 'day',
            text: t('dataBreakdownPicker.day', 'Day'),
        },
        {
            type: 'week',
            text: t('dataBreakdownPicker.week', 'Week'),
        },
        {
            type: 'month',
            text: t('dataBreakdownPicker.month', 'Month'),
        },
    ];
    //TODO: Move this out of the component if we have more paramaters for fetching data
    const [currentPeriodType, setCurrentPeriodType] = React.useState(PERIOD_VARIANTS[0]);
    const { dateRange } = useParameters();
    const { data, isLoading } = useChartData({ interval: currentPeriodType.type });
    const chartSeriesData = data
        .map((d) => [
        convertUtcToZonedTime(d.timePoint).getTime(),
        d.detectionPercentage || undefined,
    ])
        .sort();
    const xAxisRange = {
        min: startOfDay(subDays(dateRange.startDate, 1)).getTime(),
        max: endOfDay(addDays(dateRange.endDate, 1)).getTime(),
    };
    const yAxisRange = {
        min: ((_a = minBy(data.filter((it) => it.detectionPercentage > 0), 'detectionPercentage')) === null || _a === void 0 ? void 0 : _a.detectionPercentage) || 0,
        max: 100,
    };
    const options = getChartOptions(theme, xAxisRange, yAxisRange, chartSeriesData);
    const handleChangePeriodVariant = (variant) => {
        setCurrentPeriodType(variant);
        trackerService.track('[Mission Control] chart period changed', {
            period: variant,
        });
    };
    if (isLoading) {
        return React.createElement(ChartLoader, null);
    }
    return (React.createElement(Card, { height: "100%", minHeight: 250, p: 6 },
        React.createElement(ResizeObserverContainer, null, (initContainerSizeObserver) => (React.createElement(React.Fragment, null,
            React.createElement(ChartHeader, { renderRightComponent: () => (React.createElement(ChartDataBreakdownSelect, { value: currentPeriodType, options: PERIOD_VARIANTS, onValueChange: handleChangePeriodVariant })) }),
            React.createElement(HighchartsReact, { highcharts: Highcharts, options: options, callback: initContainerSizeObserver, immutable: true }))))));
};
