var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isEqual } from 'lodash';
import React from 'react';
import { useTranslation } from '@corti/i18n';
import { searchInteractions, } from '@corti/mission-control-api';
import { coreStore } from 'browser/stores';
import { DEFAULT_FILTERS, useSearchState } from '../../context';
export const usePaginatedInteractions = () => {
    var _a, _b, _c, _d;
    const { t } = useTranslation('searchApp', { keyPrefix: 'grid' });
    const { pagination, sort, filters, totalItems } = useSearchState();
    const { page, setPage, getCursorForPage, clearCursors, updateCursorForPage } = pagination;
    const [items, setItems] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const serverParams = React.useMemo(() => (Object.assign(Object.assign({}, filters.serverFilterParams), sort.serverSortParams)), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sort.sortModel, (_a = filters.filterModel) === null || _a === void 0 ? void 0 : _a.items]);
    React.useEffect(() => {
        void loadData(page, getCursorForPage(page - 1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serverParams]);
    async function handlePageChange(newModel) {
        /**
         * When navigating back to the first page, we need to reset the cached cursors
         * since the first page fetches a fresh batch of recent interactions without using any cursor.
         */
        if (newModel.page === 0) {
            clearCursors();
        }
        await loadData(newModel.page, getCursorForPage(newModel.page - 1));
        setPage(newModel.page);
    }
    //TODO: Move "pagination" parameters to `usePagination` hook, same as filters or sort
    async function loadData(page, paginationObject) {
        if (isLoading)
            return;
        setIsLoading(true);
        try {
            const _a = await searchInteractions(Object.assign(Object.assign(Object.assign({}, serverParams), DEFAULT_FILTERS), { page_field_value: paginationObject === null || paginationObject === void 0 ? void 0 : paginationObject.field_value, page_id_value: paginationObject === null || paginationObject === void 0 ? void 0 : paginationObject.id_value })), { results, total_count } = _a, res = __rest(_a, ["results", "total_count"]);
            setItems(results);
            if (page === 0 && !paginationObject) {
                totalItems.set(total_count);
            }
            if (!isEqual(getCursorForPage(page), res.pagination)) {
                updateCursorForPage(page, res.pagination);
            }
        }
        catch (error) {
            coreStore.notifications.showNotification({
                type: 'error',
                message: t('fetchInteractionsError', 'Failed to fetch interactions. Please try again later.'),
            });
        }
        finally {
            setIsLoading(false);
        }
    }
    function resetPagination() {
        setPage(0);
        clearCursors();
    }
    function handleSortChange(newSortModel) {
        sort.onSortModelChange(newSortModel);
        resetPagination();
    }
    function handleFilterModalChange(model) {
        console.log('model', model);
        filters.onFilterChange(model);
        resetPagination();
    }
    return {
        items,
        isLoading,
        totalItems: totalItems.value,
        pagination: {
            pagination: true,
            paginationMode: 'server',
            paginationModel: { page, pageSize: (_b = DEFAULT_FILTERS.size) !== null && _b !== void 0 ? _b : 20 },
            rowsPerPageOptions: [(_c = DEFAULT_FILTERS.size) !== null && _c !== void 0 ? _c : 20],
            pageSizeOptions: [(_d = DEFAULT_FILTERS.size) !== null && _d !== void 0 ? _d : 20],
            onPaginationModelChange: handlePageChange,
        },
        filters: {
            filterMode: 'server',
            filterModel: filters.filterModel,
            onFilterModelChange: handleFilterModalChange,
        },
        sort: {
            sortingMode: 'server',
            sortModel: sort.sortModel,
            onSortModelChange: handleSortChange,
        },
    };
};
