import { isDate } from 'lodash';
import { convertZonedToUtcTime } from '@corti/date';
export const DEFAULT_FILTERS = {
    size: 20,
};
const OPERATORS_MAP = {
    string: {
        contains: 'contains',
        doesNotContain: 'not_contains',
        equals: 'equals',
        doesNotEqual: 'not_equals',
        startsWith: 'starts',
        endsWith: 'ends',
        isEmpty: 'empty',
        isNotEmpty: 'empty',
        isAnyOf: 'any_of',
    },
    dateTime: {
        is: 'equals',
        not: 'not_equals',
        after: 'gt',
        onOrAfter: 'gte',
        before: 'lt',
        onOrBefore: 'lte',
        isEmpty: 'empty',
        isNotEmpty: 'empty',
    },
    number: {
        '=': 'equals',
        '!=': 'not_equals',
        '>': 'gt',
        '>=': 'gte',
        '<': 'lt',
        '<=': 'lte',
        isEmpty: 'empty',
        isNotEmpty: 'empty',
        isAnyOf: 'any_of',
    },
    boolean: {
        is: 'equals',
    },
    singleSelect: {
        is: 'equals',
        not: 'not_equals',
        isAnyOf: 'any_of',
    },
};
export function modifyFilterValue(field, value, operator) {
    if (field === 'call.started_at' && isDate(value))
        return convertZonedToUtcTime(value).toISOString();
    if (!value && operator === 'isEmpty')
        return true;
    if (!value && operator === 'isNotEmpty')
        return false;
    if (operator === 'isAnyOf' && Array.isArray(value)) {
        return value.join(',');
    }
    return value;
}
export function getOperator(operator, columnType) {
    if (!columnType || !(columnType in OPERATORS_MAP)) {
        throw new Error(`[Search] Unsupported column type: ${columnType}`);
    }
    return OPERATORS_MAP[columnType][operator] || operator;
}
